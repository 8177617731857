<template>
  <v-container>
    <h4>A recovery code will be sent to your email</h4>
    <v-text-field
      type="email"
      class="form-control"
      placeholder="Enter your email address "/>
    <v-btn
      color="success"
    >Sumbit</v-btn>
  </v-container>
</template>

<script>
export default {
  name: 'ForgotPasswordVue'
}
</script>

<style scoped>

</style>
